@content5: content5;
.@{content5}-wrapper {
  background-color: #fafafa;
  min-height: 720px;
  .@{content5} {
    > p {
      text-align: center;
    }
    &-img-wrapper {
      margin: 0 auto;
      left: 0;
      right: 0;
      .block {
        margin-bottom: 24px;
        .content5-block-content {
          display: block;
          background: #fff;
          border-radius: 4px;
          padding: 10px;
          text-align: center;
          position: relative;
          overflow: hidden;
          .page-pro();
          border: none;
          transition: box-shadow 0.3s @ease-out, transform 0.3s @ease-out;
          & > span {
            width: 100%;
            height: 160px;
            display: block;
            background: @line-color;
            padding: 5%;
          }
          & p {
            width: 100%;
            line-height: 30px;
          }
          &:hover {
            & p {
              bottom: 0;
            }
          }
        }
      }
    }
  }
}
////新增样式
.product-container {
  background-color: #f5f5f5;
  width: 100%;
  height: 80vh;
  .product-content {
    height: 100%;
    max-width: 1200px;
    position: relative;
    margin: auto;
    padding: 64px 24px;

    .product-title {
      display: block;
      font-size: 32px;
      color: rgba(0, 0, 0, 0.65);
      margin: 0 auto 16px;
      text-align: center;
    }
    .product-item-box {
      height: 220px;
      margin: 10px 0;
      padding-left: 16px;
      padding-right: 16px;

      .product-item {
        height: 100%;
        background-color: #ebf2ff;

        &:hover {
          background-color: #2c3542;

          .product-content-box {
            p {
              color: #e7951e;
            }

            .block {
              display: block;
              position: absolute;
              top: 0;
              left: 50%;
              margin-left: -85px;
            }
          }
        }
        .product-bg {
          width: 100%;
          height: 100%;
        }
        .product-content-box {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-right: 35px;

          p {
            color: #616366;
            font-size: 18px;
            margin-top: 10px;
          }
          span {
            width: 100%;
            text-align: center;
            padding: 2px 10px;
            color: #919192;
            font-size: 12px;
            word-break: break-all;
          }
          .block {
            display: none;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .product-container {
    height: 106vh;
  }
}
@media screen and (max-width: 767px) {
  .@{content5}-wrapper {
    height: 2000px;
    overflow: hidden;
    .@{content5} {
      ul {
        li {
          display: block;
          width: 100%;
          padding: 2%;
          span {
            height: 168px;
          }
          p {
            position: relative;
            bottom: 0;
          }
        }
      }
    }
  }
  .product-container {
    height: 340vh;
  }
}
