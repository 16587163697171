body {
  word-wrap: break-word;
}

/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */

.video {
  max-width: 800px;
}

#react-content {
  min-height: 100%;
}
