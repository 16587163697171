html,
body {
  font-family: "PingFang SC", "Helvetica Neue", Helvetica, "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", Arial, sans-serif;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
body video {
  display: block;
}
.text-center {
  text-align: center;
}
a {
  transition: color 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
h1,
h2,
h3,
h4 {
  color: #404040;
}
#react-content {
  width: 100%;
  overflow: hidden;
}
#page-404 {
  min-height: 680px;
  text-align: center;
  padding-top: 10%;
  color: #999;
}
#page-404 h1 {
  text-shadow: -1px -1px 4px #666;
  font-size: 200px;
}
#nprogress .bar {
  background: #1890ff;
}
#nprogress .peg {
  box-shadow: 0 0 10px #1890ff, 0 0 5px #1890ff;
}
#nprogress .spinner-icon {
  border-top-color: #1890ff;
  border-left-color: #1890ff;
}
.content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  position: absolute !important;
  width: 100%;
}
.video {
  max-width: 800px;
}
.templates-wrapper {
  user-select: none;
}
.is-edit * {
  pointer-events: none;
}
#react-content {
  min-height: 100%;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.content-template-wrapper {
  width: 100%;
  background: #fff;
  height: 100vh;
  border-color: #666;
  position: relative;
}
.content-template-wrapper .content-template {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: auto;
  position: relative;
}
.content-template-wrapper .content-template h1 {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  line-height: 48px;
}
.content-template-wrapper .content-template > p {
  font-size: 12px;
  margin: 20px auto;
}
.content-half-wrapper {
  height: 50vh;
}
@media screen and (max-width: 1024px) {
  .content-half-wrapper {
    height: 80vh;
  }
}
@media screen and (max-width: 767px) {
  .content-template-wrapper .content-template {
    /*h1, > p {
        text-align: center;
      }*/
  }
  .content-template-wrapper .content-template h1 {
    font-size: 24px;
  }
}
.header0 {
  height: 64px;
  background: rgba(51, 51, 51, 0.95);
  width: 100%;
  z-index: 970;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
}
.header0-logo {
  display: inline-block;
  position: absolute;
  left: 4%;
  width: 150px;
  line-height: 64px;
}
.header0-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header0-logo a {
  display: block;
}
.header0-nav {
  float: right;
  margin-right: 4%;
  line-height: 64px;
  position: relative;
}
.header0-nav .ant-menu-horizontal {
  color: #fff;
  border-bottom-color: transparent;
}
.header0-nav .ant-menu {
  background: transparent;
  color: #FFF;
  line-height: 62px;
}
.header0-nav .ant-menu li {
  float: left;
  text-align: center;
  width: 100px;
}
.header0-nav .ant-menu-horizontal > .ant-menu-item-active {
  color: #ce7931 !important;
  border-bottom-color: #ce7931 !important;
}
.header0 .ant-menu-horizontal > .ant-menu-item > a {
  color: #fff;
}
.header0 .ant-menu-horizontal > .ant-menu-item:hover,
.header0 .ant-menu-horizontal > .ant-menu-submenu:hover,
.header0 .ant-menu-horizontal > .ant-menu-item-active,
.header0 .ant-menu-horizontal > .ant-menu-submenu-active,
.header0 .ant-menu-horizontal > .ant-menu-item-open,
.header0 .ant-menu-horizontal > .ant-menu-submenu-open,
.header0 .ant-menu-horizontal > .ant-menu-item-selected,
.header0 .ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid #ce7931;
  color: #ce7931;
}
.header0 .ant-menu-horizontal > .ant-menu-item:hover a,
.header0 .ant-menu-horizontal > .ant-menu-submenu:hover a,
.header0 .ant-menu-horizontal > .ant-menu-item-active a,
.header0 .ant-menu-horizontal > .ant-menu-submenu-active a,
.header0 .ant-menu-horizontal > .ant-menu-item-open a,
.header0 .ant-menu-horizontal > .ant-menu-submenu-open a,
.header0 .ant-menu-horizontal > .ant-menu-item-selected a,
.header0 .ant-menu-horizontal > .ant-menu-submenu-selected a {
  color: #ce7931;
}
.header0 .ant-menu-horizontal > .ant-menu-item:hover,
.header0 .ant-menu-horizontal > .ant-menu-submenu:hover,
.header0 .ant-menu-horizontal > .ant-menu-item-active,
.header0 .ant-menu-horizontal > .ant-menu-submenu-active,
.header0 .ant-menu-horizontal > .ant-menu-item-open,
.header0 .ant-menu-horizontal > .ant-menu-submenu-open,
.header0 .ant-menu-horizontal > .ant-menu-item-selected,
.header0 .ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid #ce7931;
  color: #ce7931;
}
@media screen and (max-width: 767px) {
  .header0-logo {
    z-index: 101;
  }
  .header0-phone-nav {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 20px;
  }
  .header0-phone-nav-bar {
    position: relative;
    z-index: 100;
  }
  .header0-phone-nav-bar em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-phone-nav-bar :first-child {
    margin-top: 0;
  }
  .header0-phone-nav.open .header0-phone-nav-bar em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header0-phone-nav.open .header0-phone-nav-bar em:nth-child(2) {
    opacity: 0;
  }
  .header0-phone-nav.open .header0-phone-nav-bar em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header0-phone-nav.open .header0-phone-nav-text {
    opacity: 1;
    pointer-events: auto;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .header0-phone-nav-text {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding-top: 64px;
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    pointer-events: none;
    background: #404040;
  }
  .header0-phone-nav-text .ant-menu-item-selected {
    border: none;
  }
}
.banner2 {
  width: 100%;
  height: calc(100vh - 64px);
  position: relative;
  border-color: #666;
  background: #fff;
}
.banner2 .banner-anim {
  height: 100%;
}
.banner2 .queue-anim-leaving {
  position: relative !important;
  width: auto;
}
.banner2 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner2 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.banner2 .bg0 {
  background: url('../../../img/Product/productbg.jpg') center;
  background-size: cover;
  transform: translateY(-100px);
  height: calc(100% + 200px);
  top: -100px;
  width: calc(100% + 30px);
  left: -15px;
}
.banner2 .bg0:before {
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.35);
  display: block;
}
.banner2 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner2 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner2-title {
  display: inline-block;
  position: relative;
  top: 25%;
  left: 10%;
  font-size: 14px;
  color: #FFF;
  width: 400px;
  text-align: left;
  padding: 20px;
}
.banner2-title .logo {
  width: 350px;
  left: 0;
  margin: auto;
  display: inline-block;
  position: relative;
  font-size: 56px;
}
.banner2-title p {
  word-wrap: break-word;
  margin: auto auto 20px auto;
  font-weight: lighter;
}
.banner2-title button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  transition: background 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19), border 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  line-height: 36px;
  font-size: 16px;
  height: 36px;
}
.banner2-title button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner2-title button:hover {
  color: #fff;
  border-color: #1890ff;
  background: #1890ff;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), border 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner2-title button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.banner2-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #FFF;
}
.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 414px) {
  .banner2-title {
    width: 90%;
    left: 0;
    margin: auto;
    padding: 0;
    text-align: center;
    display: block;
  }
  .banner2-title .logo {
    width: 90%;
    left: 0;
  }
}
.content7-wrapper {
  height: 500px;
  overflow: hidden;
}
.content7 > h1 {
  text-align: center;
  position: relative;
  top: 15%;
  margin: auto;
}
.content7 > ul {
  position: relative;
  top: 25%;
  padding: 20px 0;
}
.content7 > ul > li {
  width: 33.33%;
  padding: 0 4%;
  display: inline-block;
  text-align: center;
}
.content7 > ul > li.queue-anim-leaving {
  position: relative !important;
}
.content7 > ul > li .icon {
  width: 100px;
  height: 100px;
  margin: auto;
  display: flex;
  align-items: center;
}
.content7 > ul > li > h3 {
  line-height: 32px;
  margin: 10px auto;
}
@media screen and (max-width: 767px) {
  .content7-wrapper {
    height: 760px;
  }
  .content7-wrapper .content7 {
    width: 90%;
    max-width: 100%;
  }
  .content7-wrapper .content7 > h1 {
    top: auto;
    margin: 40px auto 20px;
  }
  .content7-wrapper .content7 > ul {
    top: auto;
    padding: 0;
  }
  .content7-wrapper .content7 > ul > li {
    width: 100%;
    max-width: 250px;
    display: block;
    margin: 20px auto 40px;
    padding: 0;
  }
}
.content0-img {
  height: 100%;
  width: 40%;
  overflow: hidden;
  position: absolute;
  left: 0;
}
.content0-img span {
  display: inline-block;
  position: absolute;
  width: 55%;
  right: 10%;
  line-height: 50vh;
  height: 50vh;
  margin: auto;
  top: 0;
  bottom: 0;
}
.content0-img span img {
  vertical-align: middle;
}
.content0-text {
  display: block;
  width: 55%;
  height: 150px;
  vertical-align: top;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
}
.content0-text p,
.content0-text h1 {
  position: relative !important;
  width: 75%;
}
.content0-text h1 {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
}
.content0-text p {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    height: 400px;
  }
  .content0-wrapper .content0 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .content0-wrapper .content0-img,
  .content0-wrapper .content0-text {
    width: 100%;
    display: block;
    position: relative;
    text-align: center;
  }
  .content0-wrapper .content0-img {
    height: 200px;
    margin: 20px auto;
  }
  .content0-wrapper .content0-img span {
    right: 0;
    left: 0;
    margin: auto;
    width: 180px;
    height: 200px;
    line-height: 200px;
  }
  .content0-wrapper .content0-text {
    height: 140px;
    margin-bottom: 20px;
  }
  .content0-wrapper .content0-text p,
  .content0-wrapper .content0-text h1 {
    width: 100%;
    top: auto;
  }
  .content0-wrapper .content0-text h1 {
    margin: 10px auto;
    font-size: 24px;
  }
}
.content1-img {
  height: 100%;
  width: 40%;
  overflow: hidden;
  position: absolute;
  right: 0;
}
.content1-img span {
  display: block;
  position: absolute;
  width: 55%;
  left: 10%;
  line-height: 50vh;
  height: 50vh;
  margin: auto;
  top: 0;
  bottom: 0;
}
.content1-img span img {
  vertical-align: middle;
}
.content1-text {
  display: block;
  width: 55%;
  height: 150px;
  vertical-align: top;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.content1-text p,
.content1-text h1 {
  position: relative !important;
  width: 75%;
  float: right;
}
.content1-text h1 {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
}
.content1-text p {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 400px;
  }
  .content1-wrapper .content1 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .content1-wrapper .content1-img,
  .content1-wrapper .content1-text {
    width: 100%;
    display: block;
    position: relative;
    text-align: center;
  }
  .content1-wrapper .content1-img {
    margin: 20px auto;
    height: 200px;
  }
  .content1-wrapper .content1-img span {
    right: 0;
    left: 0;
    margin: auto;
    width: 180px;
    height: 200px;
    line-height: 200px;
  }
  .content1-wrapper .content1-text {
    height: 140px;
    margin-top: 20px;
  }
  .content1-wrapper .content1-text p,
  .content1-wrapper .content1-text h1 {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text p {
    margin-top: 20px;
  }
  .content1-wrapper .content1-text h1 {
    font-size: 24px;
  }
}
.content7-wrapper {
  height: 500px;
  overflow: hidden;
}
.content7 > h1 {
  text-align: center;
  position: relative;
  top: 15%;
  margin: auto;
}
.content7 > ul {
  position: relative;
  top: 25%;
  padding: 20px 0;
}
.content7 > ul > li {
  width: 33.33%;
  padding: 0 4%;
  display: inline-block;
  text-align: center;
}
.content7 > ul > li.queue-anim-leaving {
  position: relative !important;
}
.content7 > ul > li .icon {
  width: 100px;
  height: 100px;
  margin: auto;
  display: flex;
  align-items: center;
}
.content7 > ul > li > h3 {
  line-height: 32px;
  margin: 10px auto;
}
@media screen and (max-width: 767px) {
  .content7-wrapper {
    height: 760px;
  }
  .content7-wrapper .content7 {
    width: 90%;
    max-width: 100%;
  }
  .content7-wrapper .content7 > h1 {
    top: auto;
    margin: 40px auto 20px;
  }
  .content7-wrapper .content7 > ul {
    top: auto;
    padding: 0;
  }
  .content7-wrapper .content7 > ul > li {
    width: 100%;
    max-width: 250px;
    display: block;
    margin: 20px auto 40px;
    padding: 0;
  }
}
.footer0 {
  background-color: #333;
  text-align: center;
  height: 80px;
  color: #666;
  overflow: hidden;
  position: relative;
}
.footer0 span {
  display: block;
  margin: 20px auto 0;
}
@media screen and (max-width: 767px) {
  .footer0 > div {
    width: 90%;
    margin: auto;
  }
}
.content5-text {
  width: 35%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  top: 15%;
  left: 5%;
}
.content5-text > *.queue-anim-leaving {
  position: relative !important;
}
.content5-text > h1 {
  position: relative;
}
.content5-text > p {
  position: relative;
  margin-top: 10px;
}
.content5-text ul {
  position: relative;
  margin-top: 10%;
  display: inline-block;
}
.content5-text ul li {
  margin-top: 8%;
}
.content5-text ul li span {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
}
.content5-text ul li h2,
.content5-text ul li p {
  margin-left: 45px;
}
.content5-text ul li h2 {
  font-size: 14px;
  margin-bottom: 10px;
}
.content5-img {
  width: 50%;
  display: inline-block;
  position: relative;
  float: right;
  top: 15%;
  right: 5%;
}
@media screen and (max-width: 767px) {
  .content5-wrapper {
    height: 800px;
    overflow: hidden;
  }
  .content5-wrapper .content5 {
    width: 90%;
    max-width: 100%;
  }
  .content5-wrapper .content5-img,
  .content5-wrapper .content5-text {
    display: block;
    width: 100%;
    top: auto;
    left: auto;
    right: auto;
  }
  .content5-wrapper .content5-text {
    height: 430px;
  }
  .content5-wrapper .content5-text > h1 {
    margin: 40px auto 20px;
    font-size: 24px;
  }
  .content5-wrapper .content5-text > h1,
  .content5-wrapper .content5-text > p {
    text-align: center;
  }
  .content5-wrapper .content5-img {
    margin-top: 20px;
  }
}
@media screen and (min-width: 768px) {
  #content_4_0-title {
    font-size: 36px;
  }
}
@media screen and (max-width: 767px) {
  #content_2_0 {
    height: 400px;
  }
}
