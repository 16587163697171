html,
body {
  font-family: "PingFang SC", "Helvetica Neue", Helvetica, "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", Arial, sans-serif;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
body video {
  display: block;
}
.text-center {
  text-align: center;
}
a {
  transition: color 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
h1,
h2,
h3,
h4 {
  color: #404040;
}
#react-content {
  width: 100%;
  overflow: hidden;
}
#page-404 {
  min-height: 680px;
  text-align: center;
  padding-top: 10%;
  color: #999;
}
#page-404 h1 {
  text-shadow: -1px -1px 4px #666;
  font-size: 200px;
}
#nprogress .bar {
  background: #1890ff;
}
#nprogress .peg {
  box-shadow: 0 0 10px #1890ff, 0 0 5px #1890ff;
}
#nprogress .spinner-icon {
  border-top-color: #1890ff;
  border-left-color: #1890ff;
}
.content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  position: absolute !important;
  width: 100%;
}
.video {
  max-width: 800px;
}
.templates-wrapper {
  user-select: none;
}
.is-edit * {
  pointer-events: none;
}
#react-content {
  min-height: 100%;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.content-template-wrapper {
  width: 100%;
  background: #fff;
  height: 100vh;
  border-color: #666;
  position: relative;
}
.content-template-wrapper .content-template {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: auto;
  position: relative;
}
.content-template-wrapper .content-template h1 {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  line-height: 48px;
}
.content-template-wrapper .content-template > p {
  font-size: 12px;
  margin: 20px auto;
}
.content-half-wrapper {
  height: 50vh;
}
@media screen and (max-width: 767px) {
  .content-template-wrapper .content-template {
    /*h1, > p {
        text-align: center;
      }*/
  }
  .content-template-wrapper .content-template h1 {
    font-size: 24px;
  }
}
.content0-img {
  height: 100%;
  width: 40%;
  overflow: hidden;
  position: absolute;
  left: 0;
}
.content0-img span {
  display: inline-block;
  position: absolute;
  width: 55%;
  right: 10%;
  line-height: 50vh;
  height: 50vh;
  margin: auto;
  top: 0;
  bottom: 0;
}
.content0-img span img {
  vertical-align: middle;
}
.content0-text {
  display: block;
  width: 55%;
  height: 150px;
  vertical-align: top;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
}
.content0-text p,
.content0-text h1 {
  position: relative !important;
  width: 75%;
}
.content0-text h1 {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
}
.content0-text p {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    height: 400px;
  }
  .content0-wrapper .content0 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .content0-wrapper .content0-img,
  .content0-wrapper .content0-text {
    width: 100%;
    display: block;
    position: relative;
    text-align: center;
  }
  .content0-wrapper .content0-img {
    height: 200px;
    margin: 20px auto;
  }
  .content0-wrapper .content0-img span {
    right: 0;
    left: 0;
    margin: auto;
    width: 180px;
    height: 200px;
    line-height: 200px;
  }
  .content0-wrapper .content0-text {
    height: 140px;
    margin-bottom: 20px;
  }
  .content0-wrapper .content0-text p,
  .content0-wrapper .content0-text h1 {
    width: 100%;
    top: auto;
  }
  .content0-wrapper .content0-text h1 {
    margin: 10px auto;
    font-size: 24px;
  }
}
.content1-img {
  height: 100%;
  width: 40%;
  overflow: hidden;
  position: absolute;
  right: 0;
}
.content1-img span {
  display: block;
  position: absolute;
  width: 55%;
  left: 10%;
  line-height: 50vh;
  height: 50vh;
  margin: auto;
  top: 0;
  bottom: 0;
}
.content1-img span img {
  vertical-align: middle;
}
.content1-text {
  display: block;
  width: 55%;
  height: 150px;
  vertical-align: top;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.content1-text p,
.content1-text h1 {
  position: relative !important;
  width: 75%;
  float: right;
}
.content1-text h1 {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
}
.content1-text p {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 400px;
  }
  .content1-wrapper .content1 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .content1-wrapper .content1-img,
  .content1-wrapper .content1-text {
    width: 100%;
    display: block;
    position: relative;
    text-align: center;
  }
  .content1-wrapper .content1-img {
    margin: 20px auto;
    height: 200px;
  }
  .content1-wrapper .content1-img span {
    right: 0;
    left: 0;
    margin: auto;
    width: 180px;
    height: 200px;
    line-height: 200px;
  }
  .content1-wrapper .content1-text {
    height: 140px;
    margin-top: 20px;
  }
  .content1-wrapper .content1-text p,
  .content1-wrapper .content1-text h1 {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text p {
    margin-top: 20px;
  }
  .content1-wrapper .content1-text h1 {
    font-size: 24px;
  }
}
.content1-img {
  height: 100%;
  width: 40%;
  overflow: hidden;
  position: absolute;
  right: 0;
}
.content1-img span {
  display: block;
  position: absolute;
  width: 55%;
  left: 10%;
  line-height: 50vh;
  height: 50vh;
  margin: auto;
  top: 0;
  bottom: 0;
}
.content1-img span img {
  vertical-align: middle;
}
.content1-text {
  display: block;
  width: 55%;
  height: 150px;
  vertical-align: top;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.content1-text p,
.content1-text h1 {
  position: relative !important;
  width: 75%;
  float: right;
}
.content1-text h1 {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
}
.content1-text p {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 400px;
  }
  .content1-wrapper .content1 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .content1-wrapper .content1-img,
  .content1-wrapper .content1-text {
    width: 100%;
    display: block;
    position: relative;
    text-align: center;
  }
  .content1-wrapper .content1-img {
    margin: 20px auto;
    height: 200px;
  }
  .content1-wrapper .content1-img span {
    right: 0;
    left: 0;
    margin: auto;
    width: 180px;
    height: 200px;
    line-height: 200px;
  }
  .content1-wrapper .content1-text {
    height: 140px;
    margin-top: 20px;
  }
  .content1-wrapper .content1-text p,
  .content1-wrapper .content1-text h1 {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text p {
    margin-top: 20px;
  }
  .content1-wrapper .content1-text h1 {
    font-size: 24px;
  }
}
.content0-img {
  height: 100%;
  width: 40%;
  overflow: hidden;
  position: absolute;
  left: 0;
}
.content0-img span {
  display: inline-block;
  position: absolute;
  width: 55%;
  right: 10%;
  line-height: 50vh;
  height: 50vh;
  margin: auto;
  top: 0;
  bottom: 0;
}
.content0-img span img {
  vertical-align: middle;
}
.content0-text {
  display: block;
  width: 55%;
  height: 150px;
  vertical-align: top;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
}
.content0-text p,
.content0-text h1 {
  position: relative !important;
  width: 75%;
}
.content0-text h1 {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
}
.content0-text p {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    height: 400px;
  }
  .content0-wrapper .content0 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .content0-wrapper .content0-img,
  .content0-wrapper .content0-text {
    width: 100%;
    display: block;
    position: relative;
    text-align: center;
  }
  .content0-wrapper .content0-img {
    height: 200px;
    margin: 20px auto;
  }
  .content0-wrapper .content0-img span {
    right: 0;
    left: 0;
    margin: auto;
    width: 180px;
    height: 200px;
    line-height: 200px;
  }
  .content0-wrapper .content0-text {
    height: 140px;
    margin-bottom: 20px;
  }
  .content0-wrapper .content0-text p,
  .content0-wrapper .content0-text h1 {
    width: 100%;
    top: auto;
  }
  .content0-wrapper .content0-text h1 {
    margin: 10px auto;
    font-size: 24px;
  }
}
.content2 > h1,
.content2 > p {
  text-align: center;
  position: relative;
  top: 15%;
}
.content2-contentWrapper {
  position: relative;
  top: 20%;
  height: 60%;
}
.content2-contentWrapper ul > li {
  display: inline-block;
  width: 33.33%;
  padding: 6% 5% 0;
  vertical-align: top;
}
.content2-contentWrapper ul > li .img {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.content2-contentWrapper ul > li .text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
@media screen and (max-width: 767px) {
  .content2-wrapper {
    height: 920px;
  }
  .content2-wrapper .content2 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .content2-wrapper .content2 > h1,
  .content2-wrapper .content2 > p {
    position: relative;
    top: auto;
  }
  .content2-wrapper .content2 > h1 {
    margin: 40px auto 20px;
    font-size: 24px;
  }
  .content2-wrapper .content2-contentWrapper {
    top: auto;
    margin: 20px auto;
    height: auto;
  }
  .content2-wrapper .content2-contentWrapper ul > li {
    position: relative;
    width: 90%;
    margin: auto;
    display: block;
  }
  .content2-wrapper .content2-contentWrapper ul > li h1 {
    font-size: 20px;
  }
  .content2-wrapper .content2-contentWrapper ul > li.queue-anim-leaving {
    position: relative !important;
  }
}
.footer0 {
  background-color: #333;
  text-align: center;
  height: 80px;
  color: #666;
  overflow: hidden;
  position: relative;
}
.footer0 span {
  display: block;
  margin: 20px auto 0;
}
@media screen and (max-width: 767px) {
  .footer0 > div {
    width: 90%;
    margin: auto;
  }
}
@media screen and (max-width: 767px) {
  #content_2_0 {
    height: 450px;
  }
  #content_3_0 {
    height: 450px;
  }
  #content_2_1 {
    height: 450px;
  }
  #content_3_1 {
    height: 450px;
  }
}
