.business-box {
  background: url("../../../img/Home/earth-bg.png") no-repeat;
  width: 100%;
  height: 800px;
  background-size: cover;
  position: relative;
  padding: 64px 24px;
}
.business-box .business-title {
  display: block;
  font-size: 32px;
  color: #fff;
  margin: 0 auto 16px;
  text-align: center;
}
.business-box .logo {
  border-radius: 50%;
  position: absolute;
  top: 380px;
  left: 950px;
}
.business-box .dot1:after,
.business-box .dot2:after {
  content: "";
  position: absolute;
  margin-left: -25px;
  margin-top: -25px;
  z-index: 1;
  background-color: rgba(247, 147, 30, 0.64);
  opacity: 0.4;
  border-radius: 50%;
  box-shadow: inset 0 0 50px rgba(255, 66, 0, 0.5);
}
.business-box .dot1:after {
  width: 80px;
  height: 80px;
  top: 432px;
  left: 1010px;
  opacity: 0.3;
  -webkit-animation: ripple1 3s ease 0s infinite normal;
  animation: ripple1 3s ease 0s infinite normal;
}
.business-box .dot2:after {
  width: 100px;
  height: 100px;
  top: 432px;
  left: 1010px;
  opacity: 0.2;
  -webkit-animation: ripple2 3s ease 1.5s infinite normal;
  animation: ripple2 3s ease 1.5s infinite normal;
}
@keyframes ripple1 {
  0% {
    opacity: 0.3;
    width: 80px;
    height: 80px;
    margin-left: -40px;
    margin-top: -40px;
  }
  100% {
    opacity: 0.2;
    width: 180px;
    height: 180px;
    margin-left: -90px;
    margin-top: -90px;
  }
}
@keyframes ripple2 {
  0% {
    opacity: 0.2;
    width: 100px;
    height: 100px;
    margin-left: -50px;
    margin-top: -50px;
  }
  100% {
    opacity: 0.1;
    width: 260px;
    height: 260px;
    margin-left: -130px;
    margin-top: -130px;
  }
}
@media screen and (max-width: 1280px) {
  .business-box {
    height: 520px;
  }
  .business-box .logo {
    top: 250px;
    left: 630px;
    width: 80px;
  }
  .business-box .dot1:after {
    top: 284px;
    left: 669px;
    background-color: rgba(247, 147, 30, 0.26);
    width: 40px;
    height: 40px;
  }
  .business-box .dot2:after {
    width: 50px;
    height: 50px;
    top: 284px;
    left: 669px;
    background-color: rgba(247, 147, 30, 0.26);
  }
  @keyframes ripple1 {
    0% {
      opacity: 0.3;
      width: 40px;
      height: 40px;
      margin-left: -20px;
      margin-top: -20px;
    }
    100% {
      opacity: 0.2;
      width: 80px;
      height: 80px;
      margin-left: -40px;
      margin-top: -40px;
    }
  }
  @keyframes ripple2 {
    0% {
      opacity: 0.2;
      width: 50px;
      height: 50px;
      margin-left: -25px;
      margin-top: -25px;
    }
    100% {
      opacity: 0.1;
      width: 100px;
      height: 100px;
      margin-left: -50px;
      margin-top: -50px;
    }
  }
}
@media screen and (max-width: 767px) {
  .business-box {
    height: 300px;
  }
}
@media screen and (max-width: 600px) {
  .business-box {
    height: 210px;
  }
  .business-box .logo {
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
  }
  .business-box .dot1:after,
  .business-box .dot2:after {
    display: none;
  }
}
