.top-banner-bg{
    height: 470px;
    padding-top: 70px;
    background: url(../../../img/about/about-bg.png) center center no-repeat;
    background-size: cover;

    .top-position{
        width: 1000px;
        margin: 0 auto;

        .top-box{
            width: 414px;
            height: 237px;
            background: rgba(255,255,255,.75);
            box-shadow: 0 2px 10px 0 rgba(0,0,0,.01);
            margin: 50px 0;
            padding: 20px;
            box-sizing: border-box;

            .title{
                position: relative;
                padding-left: 36px;
                padding-top: 10px;

                h5{
                    font-size: 18px;
                    color: #ff9d29;
                    padding: 0 0 5px;
                }
            }
            .comp-info{
                padding: 12px 0;

                p{
                    line-height: 23px;
                    font-size: 12px;
                    color: #333;
                    text-indent: 2em;
                }
            }
            
        }
    }
} 