.sec-down {
  overflow: hidden;
  position: relative;
  width: 100%;

  .outer-right-1 {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: auto;
    position: relative;
    background-color: #fff;
    padding: 50px 0;

    .p-content {
      padding: 45px 50px 40px;

      .p-title {
        margin-left: 43px;
        color: #4e5ff7;
        margin-bottom: 16px;
        font-size: 18px;
      }
      .p {
        font-size: 14px;
        display: inline-block;
        width: 450px;

        .p-text {
          font-size: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .sec-down {
    overflow: visible;
  }
}
