.map-bg {
  height: 372px;
  padding: 0;
  background: url(../../../img/about/map.png) center 0 no-repeat;
}
.map-bg .map-position {
  width: 1000px;
  margin: 0 auto;
}
.map-bg .map-position .map-box {
  width: 400px;
  position: relative;
  top: 138px;
  left: 116px;
}
.map-bg .map-position .map-box h3 {
  font-size: 22px;
  color: #333;
  line-height: 40px;
}
