.title_bj {
  width: 100%;
}
.title_bj .sec-top {
  width: 100%;
  height: 470px;
  overflow: hidden;
  position: relative;
}
.title_bj .sec-top .top-content {
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.35);
  display: block;
  position: absolute;
  top: 0;
}
.title_bj .sec-top .solve-bj {
  width: 100%;
  height: 100%;
  vertical-align: top;
  border: none;
}
.title_bj .sec-top .top-bg-content {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
.title_bj .sec-top .top-bg-content .top-text-img {
  width: 40%;
  position: absolute;
  top: 20%;
  left: 40%;
}
.title_bj .sec-top .top-bg-content .top-text {
  position: absolute;
  top: 45%;
  left: 40%;
}
