.solution-bg {
  width: 100%;
  height: 25vh;

  img {
    display: inline-block;
  }
  span {
    display: block;
  }
}
