.customer-container {
  background-color: #f5f5f5;
  width: 100%;
  height: 50vh;
  .customer-content {
    height: 100%;
    max-width: 1200px;
    position: relative;
    margin: auto;
    padding: 64px 24px;
    .customer-title {
      display: block;
      font-size: 32px;
      color: rgba(0, 0, 0, 0.65);
      margin: 0 auto 16px;
      text-align: center;
    }
  }
}

.customer-item-box {
  height: 180px;
  margin: 10px 0;
  padding-left: 16px;
  padding-right: 16px;
  opacity: 1;
  transform: translate(0px, 0px);

  .customer-item {
    height: 100%;
    border: 1px solid #af91ed;
    padding: 10px;
    position: relative;
    &:hover {
      background-color: #000;
    }
    .customer-img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 1280px) {
  .customer-container {
    height: 70vh;
  }
}

@media screen and (max-width: 767px) {
  .customer-container {
    height: 160vh;
  }
}
