body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.banner3 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://gw.alipayobjects.com/zos/rmsportal/xTPkCNNLOnTEbGgVZOpE.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner3 .banner3-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  color: #fff;
  max-width: 845px;
  height: 500px;
  width: 80%;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}
.banner3 .banner3-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner3 .banner3-slogan {
  font-size: 68px;
  line-height: 80px;
  text-indent: 2px;
  font-weight: 600;
  margin: 26px auto 38px;
  overflow: hidden;
}
.banner3 .banner3-name-en {
  display: block;
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
}
.banner3 .banner3-name {
  font-size: 24px;
  overflow: hidden;
  opacity: 0.8;
}
.banner3 .banner3-button {
  margin: 72px auto 0;
  background: #034365;
  background: -moz-linear-gradient(left, #034365 0%, #001b33 100%);
  background: linear-gradient(to right, #034365 0%, #001b33 100%);
  box-shadow: 0 8px 16px #0a52ab;
  border: none;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 132px;
  line-height: 42px;
  height: 42px;
  border-radius: 42px;
}
.banner3 .banner3-time {
  font-size: 14px;
  line-height: 24px;
  margin-top: 24px;
}
@media screen and (max-width: 767px) {
  .banner3 {
    background-attachment: inherit;
  }
  .banner3 .banner3-text-wrapper {
    width: 90%;
    height: 50%;
  }
  .banner3 .banner3-name-en {
    font-size: 12px;
  }
  .banner3 .banner3-slogan {
    font-size: 24px;
    line-height: 1.5;
    margin: 12px 0;
  }
  .banner3 .banner3-name {
    font-size: 14px;
  }
}
.content5-wrapper {
  background-color: #fafafa;
  min-height: 720px;
}
.content5-wrapper .content5 > p {
  text-align: center;
}
.content5-wrapper .content5-img-wrapper {
  margin: 0 auto;
  left: 0;
  right: 0;
}
.content5-wrapper .content5-img-wrapper .block {
  margin-bottom: 24px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content {
  display: block;
  background: #fff;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  transform: translateY(0);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  border: none;
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover {
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content > span {
  width: 100%;
  height: 160px;
  display: block;
  background: #e9e9e9;
  padding: 5%;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content p {
  width: 100%;
  line-height: 30px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover p {
  bottom: 0;
}
.product-container {
  background-color: #f5f5f5;
  width: 100%;
  height: 80vh;
}
.product-container .product-content {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  padding: 64px 24px;
}
.product-container .product-content .product-title {
  display: block;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.65);
  margin: 0 auto 16px;
  text-align: center;
}
.product-container .product-content .product-item-box {
  height: 220px;
  margin: 10px 0;
  padding-left: 16px;
  padding-right: 16px;
}
.product-container .product-content .product-item-box .product-item {
  height: 100%;
  background-color: #ebf2ff;
}
.product-container .product-content .product-item-box .product-item:hover {
  background-color: #2c3542;
}
.product-container .product-content .product-item-box .product-item:hover .product-content-box p {
  color: #e7951e;
}
.product-container .product-content .product-item-box .product-item:hover .product-content-box .block {
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -85px;
}
.product-container .product-content .product-item-box .product-item .product-bg {
  width: 100%;
  height: 100%;
}
.product-container .product-content .product-item-box .product-item .product-content-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 35px;
}
.product-container .product-content .product-item-box .product-item .product-content-box p {
  color: #616366;
  font-size: 18px;
  margin-top: 10px;
}
.product-container .product-content .product-item-box .product-item .product-content-box span {
  width: 100%;
  text-align: center;
  padding: 2px 10px;
  color: #919192;
  font-size: 12px;
  word-break: break-all;
}
.product-container .product-content .product-item-box .product-item .product-content-box .block {
  display: none;
}
@media screen and (max-width: 1280px) {
  .product-container {
    height: 106vh;
  }
}
@media screen and (max-width: 767px) {
  .content5-wrapper {
    height: 2000px;
    overflow: hidden;
  }
  .content5-wrapper .content5 ul li {
    display: block;
    width: 100%;
    padding: 2%;
  }
  .content5-wrapper .content5 ul li span {
    height: 168px;
  }
  .content5-wrapper .content5 ul li p {
    position: relative;
    bottom: 0;
  }
  .product-container {
    height: 340vh;
  }
}
.content0-wrapper {
  height: 446px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0 .block-wrapper .block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  height: 200px;
  margin-bottom: 48px;
}
.content0-wrapper .content0 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0 .block-wrapper .block .icon {
  width: 100px;
  height: 100px;
  margin: auto;
  display: flex;
  align-items: center;
}
.content0-wrapper .content0 .block-wrapper .block .content0-title {
  line-height: 32px;
  margin: 10px auto;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    height: 880px;
  }
}
.footer1-wrapper {
  background: #21252a;
  overflow: hidden;
  position: relative;
  min-height: 400px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .footer1 .block {
  padding: 0 32px;
}
.footer1-wrapper .footer1 .block .logo {
  max-width: 180px;
}
.footer1-wrapper .footer1 .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .footer1 .block > h2 {
  margin-bottom: 24px;
  color: #fff;
}
.footer1-wrapper .footer1 .block > div {
  line-height: 24px;
}
.footer1-wrapper .footer1 .block > div a {
  color: #999;
}
.footer1-wrapper .footer1 .block > div a:hover {
  color: #1890ff;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 100px;
  text-align: center;
  line-height: 80px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    height: 550px;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
.header0 {
  background: rgba(0, 21, 41, 0.95);
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
}
.header0 .home-page {
  padding: 0 24px;
}
.header0-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header0-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header0-logo a {
  display: block;
}
.header0-menu {
  float: right;
}
.header0-menu > .ant-menu {
  line-height: 62px;
  background: transparent;
  color: #fff;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
}
.header0-menu > .ant-menu a {
  color: #fff;
}
.header0-menu > .ant-menu a:hover {
  color: #ce7931;
}
.header0 .ant-menu-item-selected a {
  color: #1890ff;
}
.mynavClass {
  background-color: rgba(18, 19, 31, 0.65) !important;
  position: fixed !important;
  top: 0;
}
.title-line {
  width: 50px;
  height: 3px;
  background-color: #fbfbfb;
  margin: 0px auto;
}
.title-black-line {
  width: 50px;
  height: 3px;
  background-color: rgba(0, 0, 0, 0.65);
  margin: 0px auto;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #ce7931 !important;
}
.isphoneshow {
  display: none;
}
@media screen and (max-width: 767px) {
  .tel {
    display: none;
  }
  .isphoneshow {
    display: block;
  }
  .home-page-wrapper {
    overflow: visible;
  }
  .header0-logo {
    z-index: 101;
  }
  .header0.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header0 .header0-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0 .header0-menu li {
    padding: 0 24px;
  }
  .header0 .header0-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header0 .header0-menu .ant-menu-submenu .ant-menu-sub {
    padding: 0 24px;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-mobile-menu :first-child {
    margin-top: 0;
  }
  .header0 .ant-menu {
    height: auto;
    overflow: hidden;
    background: transparent;
  }
  .header0 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header0 .ant-menu a {
    color: #fff;
  }
  .header0 .ant-menu a:hover {
    color: #fff;
  }
  .header0 .ant-menu .ant-menu-item-selected a {
    color: #fff;
  }
  .header0 .open {
    height: auto;
  }
  .header0 .open .header0-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header0 .open .header0-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header0 .open .header0-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header0 .open > .header0-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.templates-wrapper > .banner3 > .jos8k1kzrvc-editor_css {
  height: 50%;
  max-width: 1200px !important;
  position: absolute !important;
  left: 4% !important;
  padding: 30px 24px !important;
}
.banner3 > .banner3-text-wrapper > .jos8l1ukh7-editor_css {
  font-family: Microsoft YaHei;
  font-size: 60px;
  font-weight: 400;
}
div > .templates-wrapper > .jos8j1jraor-editor_css {
  text-align: left;
  height: 60vh;
  background-image: url(https://www.e-yuntong.com/static/img/topbg.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
}
div > .templates-wrapper > .jos1owotoeo-editor_css {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
}
.home-page > .title-wrapper > .josa1ts0lkh-editor_css {
  color: #fbfbfb;
}
.ant-row > .block > .josa77owa9-editor_css {
  color: #fbfbfb;
}
.ant-row > .block > .josa7i1hl5a-editor_css {
  color: #d9d9d9;
}
.ant-row > .block > .josa7pb11j-editor_css {
  color: #fbfbfb;
}
.ant-row > .block > .josa7zmr8i-editor_css {
  color: #d9d9d9;
}
.ant-row > .block > .josa85aesu-editor_css {
  color: #fbfbfb;
}
.ant-row > .block > .josa8bg65wb-editor_css {
  color: #d9d9d9;
}
.ant-row > .block > .josa8s9scqh-editor_css {
  color: #fbfbfb;
}
div > .templates-wrapper > .josbkfc9p7s-editor_css {
  background-color: #f5f5f5;
}
div > .ant-row > .jredqpm-editor_css {
  color: #fcfcfc;
}
.ant-row > .block > .jreds2sql7-editor_css {
  color: #d9d9d9;
}
.ant-row > .block > .jredptyxkk-editor_css {
  color: #d9d9d9;
}
.ant-row > .block > .jredp9zp6bt-editor_css {
  color: #fbfbfb;
}
.ant-row > .block > .jredsmj2z5m-editor_css {
  color: #fbfbfb;
}
div > .templates-wrapper > .josa1478br-editor_css {
  height: 700px;
  background-image: url("https://www.e-yuntong.com/static/img/advantage-bg.png");
  background-size: cover;
}
@media screen and (max-width: 767px) {
  div > .templates-wrapper > .jos8j1jraor-editor_css {
    background-repeat: no-repeat;
    background-position: center center;
  }
  .templates-wrapper > .home-page-wrapper > .josa6c82nmi-editor_css {
    height: 1700px;
    padding: 56px 24px;
  }
  div > .templates-wrapper > .josa1478br-editor_css {
    height: 1800px;
  }
  .header0 .header0-menu {
    width: 160px;
    opacity: 1;
  }
}
