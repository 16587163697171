.templates-wrapper > .banner3 > .jos8k1kzrvc-editor_css {
  height: 50%;
  max-width: 1200px !important;
  position: absolute !important;
  left: 4% !important;
  padding: 30px 24px !important;
}
.banner3 > .banner3-text-wrapper > .jos8l1ukh7-editor_css {
  font-family: Microsoft YaHei;
  font-size: 60px;
  font-weight: 400;
}
div > .templates-wrapper > .jos8j1jraor-editor_css {
  text-align: left;
  height: 60vh;
  background-image: url(https://www.e-yuntong.com/static/img/topbg.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
}
div > .templates-wrapper > .jos1owotoeo-editor_css {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
}
.ant-menu > .ant-menu-item > .jos8z6gplk-editor_css {
  // color: rgb(206, 121, 49);
}
.header0-menu > .ant-menu > .jos9ba8x3o-editor_css {
  // border-bottom-color: #da7a33;
}
.home-page > .title-wrapper > .josa1ts0lkh-editor_css {
  color: rgb(251, 251, 251);
}
.ant-row > .block > .josa77owa9-editor_css {
  color: #fbfbfb;
}
.ant-row > .block > .josa7i1hl5a-editor_css {
  color: #d9d9d9;
}
.ant-row > .block > .josa7pb11j-editor_css {
  color: #fbfbfb;
}
.ant-row > .block > .josa7zmr8i-editor_css {
  color: #d9d9d9;
}
.ant-row > .block > .josa85aesu-editor_css {
  color: #fbfbfb;
}
.ant-row > .block > .josa8bg65wb-editor_css {
  color: #d9d9d9;
}
.ant-row > .block > .josa8s9scqh-editor_css {
  color: #fbfbfb;
}
div > .templates-wrapper > .josbkfc9p7s-editor_css {
  background-color: rgb(245, 245, 245);
}
.ant-row > .block > .jredil1hkdr-editor_css {
}
div > .ant-row > .jredqpm-editor_css {
  color: #fcfcfc;
}
.ant-row > .block > .jreds2sql7-editor_css {
  color: rgb(217, 217, 217);
}
.ant-row > .block > .jredptyxkk-editor_css {
  color: rgb(217, 217, 217);
}
.ant-row > .block > .jredp9zp6bt-editor_css {
  color: rgb(251, 251, 251);
}
.ant-row > .block > .jredsmj2z5m-editor_css {
  color: rgb(251, 251, 251);
}
div > .templates-wrapper > .josa1478br-editor_css {
  height: 700px;
  background-image: url("https://www.e-yuntong.com/static/img/advantage-bg.png");
  background-size: cover;
}
@media screen and (max-width: 767px) {
  div > .templates-wrapper > .jos8j1jraor-editor_css {
    background-repeat: no-repeat;
    background-position: center center;
  }
  .templates-wrapper > .home-page-wrapper > .josa6c82nmi-editor_css {
    height: 1700px;
    padding: 56px 24px;
  }
  div > .templates-wrapper > .josa1478br-editor_css {
    height: 1800px;
  }
  .header0 .header0-menu {
    width: 160px;
    opacity: 1;
  }
}
