.top-banner-bg {
  height: 470px;
  padding-top: 70px;
  background: url(../../../img/about/about-bg.png) center center no-repeat;
  background-size: cover;
}
.top-banner-bg .top-position {
  width: 1000px;
  margin: 0 auto;
}
.top-banner-bg .top-position .top-box {
  width: 414px;
  height: 237px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.01);
  margin: 50px 0;
  padding: 20px;
  box-sizing: border-box;
}
.top-banner-bg .top-position .top-box .title {
  position: relative;
  padding-left: 36px;
  padding-top: 10px;
}
.top-banner-bg .top-position .top-box .title h5 {
  font-size: 18px;
  color: #ff9d29;
  padding: 0 0 5px;
}
.top-banner-bg .top-position .top-box .comp-info {
  padding: 12px 0;
}
.top-banner-bg .top-position .top-box .comp-info p {
  line-height: 23px;
  font-size: 12px;
  color: #333;
  text-indent: 2em;
}
