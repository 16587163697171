@import './custom.less';

@content7: content7;
.@{content7} {
  &-wrapper {
    height: 500px;
    overflow: hidden;
  }
  > h1 {
    text-align: center;
    position: relative;
    top: 15%;
    margin: auto;
  }
  > ul {
    position: relative;
    top: 25%;
    padding: 20px 0;
    > li {
      width: 33.33%;
      padding: 0 4%;
      display: inline-block;
      text-align: center;
      &.queue-anim-leaving {
        position: relative !important;
      }
      .icon {
        width: 100px;
        height: 100px;
        margin: auto;
        display: flex;
        align-items: center;
      }
      > h3 {
        line-height: 32px;
        margin: 10px auto;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content7}-wrapper {
    height: 760px;
    .@{content7} {
      width: 90%;
      max-width: 100%;
      > h1{
        top: auto;
        margin: 40px auto 20px;
      }
      > ul {
        top: auto;
        padding: 0;
        > li{
          width: 100%;
          max-width: 250px;
          display: block;
          margin: 20px auto 40px;
          padding: 0;
        }
      }
    }
  }
}