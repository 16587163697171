.join-box {
  background: url("../../img/join/translate.jpg") 50%;
  width: 100%;
  height: 55vh;
}
.join-box .job-top-content {
  text-align: center;
  top: 50%;
}
.join-box .title-top {
  color: #fff;
  font-size: 42px;
}
.join-box .title-english {
  color: #bbb;
  font-size: 32px;
}
.job-title img {
  margin: 15px auto;
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.show {
  display: block;
}
.hiden {
  display: none;
}
.job-describe {
  padding: 10px 24px 10px 34px;
  background-color: #f8f8f8;
}
.job-describe .job-describe-title {
  font-size: 14px;
  color: #555;
  position: relative;
  padding-left: 12px;
  line-height: 22px;
  height: 22px;
  margin: 10px 0;
}
.job-describe .job-describe-title:before {
  content: "";
  width: 4px;
  height: 16px;
  position: absolute;
  top: 2px;
  left: 0;
  background-color: #ff9d29;
}
