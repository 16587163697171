@import './global';

.content-wrapper > .tween-one-leaving, .queue-anim-leaving {
  position: absolute !important;
  width: 100%;
}

.video {
  max-width: 800px;
}

.templates-wrapper {
  user-select: none;
}

.is-edit {
  * {
    pointer-events: none;
  }
}

#react-content{
  min-height: 100%;
}