@import "./custom.less";

@header: header0;
.@{header} {
  height: 64px;
  background: @template-nav-bg-color;
  width: 100%;
  z-index: 970;
  box-shadow: 0 5px 8px fade(#000, 15);
  position: relative;
  top: 0;
  &-logo {
    display: inline-block;
    position: absolute;
    left: 4%;
    width: 150px;
    line-height: 64px;
    & img {
      vertical-align: middle;
      display: inline-block;
    }
    & a {
      display: block;
    }
  }
  &-nav {
    float: right;
    margin-right: 4%;
    line-height: 64px;
    position: relative;
    & .ant-menu-horizontal {
      color: #fff;
      border-bottom-color: transparent;
    }
    & .ant-menu {
      background: transparent;
      color: @template-text-color-light;
      line-height: 62px;
      & li {
        float: left;
        text-align: center;
        width: 100px;
      }
    }
    & .ant-menu-horizontal > .ant-menu-item-active {
      color: #ce7931 !important;
      border-bottom-color: #ce7931 !important;
    }
  }
  .ant-menu-horizontal > .ant-menu-item > a {
    color: #fff;
  }
  .ant-menu > .ant-menu-item > .jos8z6gplk-editor_css {
    // color: #ce7931;
  }
  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-submenu:hover,
  .ant-menu-horizontal > .ant-menu-item-active,
  .ant-menu-horizontal > .ant-menu-submenu-active,
  .ant-menu-horizontal > .ant-menu-item-open,
  .ant-menu-horizontal > .ant-menu-submenu-open,
  .ant-menu-horizontal > .ant-menu-item-selected,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    border-bottom: 2px solid #ce7931;
    color: #ce7931;
    a {
      color: #ce7931;
    }
  }
  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-submenu:hover,
  .ant-menu-horizontal > .ant-menu-item-active,
  .ant-menu-horizontal > .ant-menu-submenu-active,
  .ant-menu-horizontal > .ant-menu-item-open,
  .ant-menu-horizontal > .ant-menu-submenu-open,
  .ant-menu-horizontal > .ant-menu-item-selected,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    border-bottom: 2px solid #ce7931;
    color: #ce7931;
  }
}

@media screen and (max-width: 767px) {
  .@{header} {
    &-logo {
      z-index: 101;
    }
    &-phone-nav {
      width: 16px;
      height: 14px;
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 20px;
      &-bar {
        position: relative;
        z-index: 100;
        em {
          display: block;
          width: 100%;
          height: 2px;
          background: #fff;
          margin-top: 4px;
          transition: transform 0.3s @ease-in-out, opacity 0.3s @ease-in-out;
        }
        :first-child {
          margin-top: 0;
        }
      }
      &.open {
        .@{header}-phone-nav-bar {
          em {
            &:nth-child(1) {
              transform: translateY(6px) rotate(45deg);
            }
            &:nth-child(2) {
              opacity: 0;
            }
            &:nth-child(3) {
              transform: translateY(-6px) rotate(-45deg);
            }
          }
        }
        .@{header}-phone-nav-text {
          opacity: 1;
          pointer-events: auto;
          transition-timing-function: @ease-out;
        }
      }

      &-text {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        padding-top: 64px;
        opacity: 0;
        transition: opacity 0.3s @ease-in;
        pointer-events: none;
        background: #404040;
        .ant-menu-item-selected {
          border: none;
        }
      }
    }
  }
}
