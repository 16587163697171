.title_bj {
  width: 100%;

  .sec-top {
    width: 100%;
    height: 470px;
    overflow: hidden;
    position: relative;

    .solve-bj {
      width: 100%;
      height: 100%;
      vertical-align: top;
      border: none;
    }
    .top-bg-content {
      color: #fff;
      font-size: 18px;
      font-weight: bold;

      .top-text-img {
        width: 40%;
        position: absolute;
        top: 20%;
        left: 40%;
      }
      .top-text {
        position: absolute;
        top: 70%;
        left: 40%;
      }
    }
  }
}
